/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Types of Stripe Connect accounts
 */
export enum AccountTypeEnum {
  EXPRESS = 'EXPRESS',
  STANDARD = 'STANDARD'
}

/**
 * Status for the account verification result
 */
export enum AccountVerificationResultStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED'
}

/**
 * The type of the accountable
 */
export enum AccountableTypeEnum {
  BUYER = 'BUYER',
  REP = 'REP',
  SELLER = 'SELLER'
}

/**
 * What kind of address does it represent
 */
export enum AddressKindEnum {
  ACCOUNT = 'ACCOUNT',
  ACCOUNT_BILLING = 'ACCOUNT_BILLING',
  BILLING = 'BILLING',
  LOCATION = 'LOCATION',
  SHIPPING = 'SHIPPING',
  USER = 'USER'
}

/**
 * What kind of location is the address situated at?
 */
export enum AddressLocationTypeEnum {
  OFFICE = 'OFFICE',
  RETAIL_STORE = 'RETAIL_STORE',
  WAREHOUSE = 'WAREHOUSE'
}

/**
 * What object owns this address?
 */
export enum AddressableTypeEnum {
  BUYER = 'BUYER',
  CUSTOMER = 'CUSTOMER',
  ORDER = 'ORDER',
  SELLER = 'SELLER',
  USER = 'USER'
}

/**
 * Source of the business category taxonomy for a buyer
 */
export enum BusinessCategoryTaxonomySourceEnum {
  SUGGESTED = 'SUGGESTED',
  USER = 'USER'
}

/**
 * Buyer account status indicates whether they are active or not
 */
export enum BuyerStatusEnum {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED'
}

/**
 * Format of a CSV file to be processed
 */
export enum CSVImportFormatEnum {
  FIELDFOLIO = 'FIELDFOLIO',
  HANDSHAKE = 'HANDSHAKE'
}

/**
 * The 'kind' of CSV Import
 */
export enum CSVImportKindEnum {
  CUSTOMER_CSV_IMPORT = 'CUSTOMER_CSV_IMPORT',
  PRODUCT_CSV_IMPORT = 'PRODUCT_CSV_IMPORT',
  SOH_CSV_IMPORT = 'SOH_CSV_IMPORT'
}

/**
 * The 'kind' of CSV Import SOH
 */
export enum CSVImportSOHFormatEnum {
  ADJUSTMENT = 'ADJUSTMENT',
  OVERWRITE = 'OVERWRITE'
}

/**
 * Filter buyer catalogs to those that have been requested or approved
 */
export enum CatalogFilterApprovalStatusEnum {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED'
}

/**
 * Catalog approval status for Marketplace
 */
export enum CatalogMarketplaceApprovalStatusEnum {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED'
}

/**
 * The Australian states/territories
 */
export enum CatalogRepPromotionalAuTerritoriesEnum {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA'
}

/**
 * The New Zealand states/territories
 */
export enum CatalogRepPromotionalNzTerritoriesEnum {
  NI = 'NI',
  SI = 'SI'
}

/**
 * Sort catalogs by any of these options
 */
export enum CatalogSortingEnum {
  CREATED_AT = 'CREATED_AT',
  DEFAULT = 'DEFAULT',
  FREE_SHIPPING_OVER = 'FREE_SHIPPING_OVER',
  LAST_PUBLISHED_AT = 'LAST_PUBLISHED_AT',
  MARKETPLACE_APPROVAL_STATUS_CHANGED_AT = 'MARKETPLACE_APPROVAL_STATUS_CHANGED_AT',
  NAME = 'NAME',
  PAYMENT_TERMS_OFFER = 'PAYMENT_TERMS_OFFER',
  RANDOM = 'RANDOM'
}

/**
 * Seller's chargify status
 */
export enum ChargifyAccountStatusEnum {
  ACTIVE = 'ACTIVE',
  ASSESSING = 'ASSESSING',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED_TO_CREATE = 'FAILED_TO_CREATE',
  ON_HOLD = 'ON_HOLD',
  PAST_DUE = 'PAST_DUE',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING',
  SOFT_FAILURE = 'SOFT_FAILURE',
  SUSPENDED = 'SUSPENDED',
  TRIALING = 'TRIALING',
  TRIAL_ENDED = 'TRIAL_ENDED',
  UNPAID = 'UNPAID'
}

/**
 * Sort customers by any of these options
 */
export enum CustomerSortingEnum {
  NAME = 'NAME',
  PRICING_ACCESS_APPROVED_AT = 'PRICING_ACCESS_APPROVED_AT',
  REFERENCE = 'REFERENCE'
}

/**
 * Which index to search?
 */
export enum ElasticsearchIndexEnum {
  CATALOGS = 'CATALOGS',
  PRODUCTS = 'PRODUCTS',
  SELLERS = 'SELLERS'
}

/**
 * What is the status of the integration?
 */
export enum IntegrationAuthorizationStatusEnum {
  AUTHORIZED = 'AUTHORIZED',
  DEAUTHORIZED = 'DEAUTHORIZED',
  INITIALIZED = 'INITIALIZED'
}

/**
 * An event which triggered the inventory change for a Variety
 */
export enum InventoryHistoryKindEnum {
  CUSTOMER_API = 'CUSTOMER_API',
  INITIAL = 'INITIAL',
  INVENTORY_ADJUSTMENT = 'INVENTORY_ADJUSTMENT',
  MYOB_IMPORT = 'MYOB_IMPORT',
  MYOB_SYNC = 'MYOB_SYNC',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CANCELLED_PARTIAL = 'ORDER_CANCELLED_PARTIAL',
  ORDER_CANCELLED_PARTIAL_WITH_REFUND = 'ORDER_CANCELLED_PARTIAL_WITH_REFUND',
  ORDER_CANCELLED_WITH_REFUND = 'ORDER_CANCELLED_WITH_REFUND',
  ORDER_CANCEL_PARTIAL_UNDO = 'ORDER_CANCEL_PARTIAL_UNDO',
  ORDER_CANCEL_UNDO = 'ORDER_CANCEL_UNDO',
  ORDER_POSTED = 'ORDER_POSTED',
  PRODUCT_CREATE = 'PRODUCT_CREATE',
  PRODUCT_CSV_IMPORT = 'PRODUCT_CSV_IMPORT',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  PRODUCT_PUBLISH = 'PRODUCT_PUBLISH',
  SOH_CSV_IMPORT_ADJUSTMENT = 'SOH_CSV_IMPORT_ADJUSTMENT',
  SOH_CSV_IMPORT_OVERWRITE = 'SOH_CSV_IMPORT_OVERWRITE',
  XERO_IMPORT = 'XERO_IMPORT',
  XERO_SYNC = 'XERO_SYNC',
  ZAPIER_EVENT = 'ZAPIER_EVENT'
}

/**
 * Sort inventory history by any of these options
 */
export enum InventoryHistorySortingEnum {
  ADJUSTMENT = 'ADJUSTMENT',
  BALANCE = 'BALANCE',
  CREATED_AT = 'CREATED_AT',
  EVENT_KIND = 'EVENT_KIND',
  ORDER_ID = 'ORDER_ID',
  USER_ID = 'USER_ID'
}

export enum InvoiceStatusEnum {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID'
}

/**
 * Source of how a customer gained access to Marketplace
 */
export enum MarketSourceEnum {
  INVITATION = 'INVITATION',
  PRICING_REQUEST = 'PRICING_REQUEST'
}

/**
 * Seller migration status
 */
export enum MigrationStatusEnum {
  MIGRATED = 'MIGRATED',
  REACTIVATED = 'REACTIVATED',
  SCHEDULED_DEFAULT = 'SCHEDULED_DEFAULT',
  SCHEDULED_EARLY = 'SCHEDULED_EARLY'
}

/**
 * What's the status of the order regarding order fulfilment
 */
export enum OrderFulfilmentStatusEnum {
  CANCELLED = 'CANCELLED',
  CANCELLED_WITH_REFUND = 'CANCELLED_WITH_REFUND',
  FULFILLED = 'FULFILLED',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  UNFULFILLED = 'UNFULFILLED'
}

/**
 * An action that can be performed on an order
 */
export enum OrderManagementActionEnum {
  CANCEL = 'CANCEL',
  CANCEL_PARTIAL = 'CANCEL_PARTIAL',
  CANCEL_PARTIAL_WITH_REFUND = 'CANCEL_PARTIAL_WITH_REFUND',
  CANCEL_WITH_REFUND = 'CANCEL_WITH_REFUND',
  FULFIL = 'FULFIL',
  FULFIL_PARTIAL = 'FULFIL_PARTIAL'
}

/**
 * Payment status of an order
 */
export enum OrderPaymentStatusEnum {
  PAID = 'PAID',
  UNPAID = 'UNPAID'
}

/**
 * Payment terms for an order. Currently only used by the iPad app
 */
export enum OrderPaymentTermsEnum {
  AS_PER_INVOICE = 'AS_PER_INVOICE',
  COD = 'COD',
  NET_30 = 'NET_30',
  NET_60 = 'NET_60',
  NET_90 = 'NET_90',
  ON_ACCOUNT = 'ON_ACCOUNT',
  PREPAID = 'PREPAID'
}

/**
 * Shipping methods for an order
 */
export enum OrderShippingMethodEnum {
  AUSTRALIA_POST = 'AUSTRALIA_POST',
  CANADA_POST = 'CANADA_POST',
  CUSTOMER_TO_ARRANGE = 'CUSTOMER_TO_ARRANGE',
  DHL = 'DHL',
  FEDEX = 'FEDEX',
  NZ_POST = 'NZ_POST',
  ROYAL_MAIL = 'ROYAL_MAIL',
  SELLER_TO_ARRANGE = 'SELLER_TO_ARRANGE',
  TNT = 'TNT',
  UPS = 'UPS',
  USPS = 'USPS'
}

/**
 * Sort orders by any of these options
 */
export enum OrderSortingEnum {
  CATALOG_NAME = 'CATALOG_NAME',
  CREATED_AT = 'CREATED_AT',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  EXPORTED_AT = 'EXPORTED_AT',
  GRAND_TOTAL = 'GRAND_TOTAL',
  HOLD = 'HOLD',
  ID = 'ID',
  POSTED_AT = 'POSTED_AT',
  REFERENCE = 'REFERENCE',
  STATUS = 'STATUS',
  USER_FULL_NAME = 'USER_FULL_NAME'
}

/**
 * What 'source' created the order
 */
export enum OrderSourceEnum {
  DIRECT = 'DIRECT',
  FIELD = 'FIELD',
  MARKET = 'MARKET'
}

/**
 * Status of an order
 */
export enum OrderStatusEnum {
  BACKORDER = 'BACKORDER',
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  POSTED = 'POSTED'
}

/**
 * Format of a Orders CSV file to be exported
 */
export enum OrdersCSVExportFormatEnum {
  GENERIC = 'GENERIC',
  MYOB = 'MYOB',
  MYOB_NZ = 'MYOB_NZ',
  PRONTO = 'PRONTO',
  QUICKBOOKS = 'QUICKBOOKS',
  SAASU = 'SAASU',
  SALES_HISTORY = 'SALES_HISTORY',
  SAP_B1 = 'SAP_B1',
  UNLEASHED = 'UNLEASHED',
  XERO = 'XERO'
}

/**
 * Seller payment method status
 */
export enum PaymentMethodStatusEnum {
  INVALID = 'INVALID',
  MISSING = 'MISSING',
  VALID = 'VALID'
}

/**
 * payment term offers
 */
export enum PaymentTermsOfferEnum {
  UP_TO_30_DAYS = 'UP_TO_30_DAYS',
  UP_TO_60_DAYS = 'UP_TO_60_DAYS',
  UP_TO_90_DAYS = 'UP_TO_90_DAYS'
}

/**
 * Available plans
 */
export enum PlanEnum {
  BUSINESS = 'BUSINESS',
  PER_ORDER = 'PER_ORDER'
}

/**
 * Sort buyer pricing requests by any of these options
 */
export enum PricingRequestSortingEnum {
  APPROVED_AT = 'APPROVED_AT',
  BUYER_ID = 'BUYER_ID',
  CREATED_AT = 'CREATED_AT',
  REQUESTED_AT = 'REQUESTED_AT',
  STATUS = 'STATUS'
}

/**
 * Possible sources of a pricing request.
 */
export enum PricingRequestSourceEnum {
  BUYER_REQUESTED = 'BUYER_REQUESTED',
  RECOMMENDATION = 'RECOMMENDATION',
  RECOMMENDATION_ALL = 'RECOMMENDATION_ALL',
  RECOMMENDATION_EMAIL = 'RECOMMENDATION_EMAIL',
  RECOMMENDATION_EMAIL_ALL = 'RECOMMENDATION_EMAIL_ALL',
  SELLER_INVITED = 'SELLER_INVITED'
}

/**
 * Status of a 'pricing request'. Will be approved/rejected by the seller
 */
export enum PricingRequestStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}

/**
 * A 'badge' is just an informational label to provide more information on the product
 */
export enum ProductBadgeEnum {
  BEST_SELLER = 'BEST_SELLER',
  DISCONTINUED = 'DISCONTINUED',
  NEW = 'NEW',
  SOLD_OUT = 'SOLD_OUT',
  SPECIAL = 'SPECIAL',
  UPCOMING = 'UPCOMING'
}

/**
 * Sort products by any of these options
 */
export enum ProductSortingEnum {
  CREATED_AT = 'CREATED_AT',
  CUSTOM_TAGS = 'CUSTOM_TAGS',
  LABELS = 'LABELS',
  MINIMUM = 'MINIMUM',
  POSITION = 'POSITION',
  PRICE = 'PRICE',
  PRODUCT_NAME = 'PRODUCT_NAME',
  PUBLISHED_AT = 'PUBLISHED_AT',
  SKU = 'SKU',
  SOH = 'SOH',
  STOCK_DUE = 'STOCK_DUE'
}

/**
 * The type of discount for a promotion coupon
 */
export enum PromotionCouponDiscountTypeEnum {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
}

/**
 * Represents the destination of the email 'link', i.e. whether it should link to the 'product' or the 'catalog category'
 */
export enum PromotionSlotContentLinkTargetEnum {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT'
}

/**
 * Approval status for promotional content
 */
export enum PromotionSlotContentStatusEnum {
  APPROVED = 'APPROVED',
  AWAITING_CONTENT = 'AWAITING_CONTENT',
  SUBMITTED = 'SUBMITTED'
}

/**
 * Availability status for promotion slot
 */
export enum PromotionSlotStatusEnum {
  APPROVED = 'APPROVED',
  AVAILABLE = 'AVAILABLE',
  PENDING = 'PENDING',
  PURCHASED = 'PURCHASED',
  SUBMITTED = 'SUBMITTED'
}

/**
 * The type of promotion. Different promotion types appear differently on Fieldfolio
 */
export enum PromotionTypeEnum {
  DEPARTMENT_FEATURE = 'DEPARTMENT_FEATURE',
  HOMEPAGE_BANNER = 'HOMEPAGE_BANNER',
  RETAILER_EMAIL = 'RETAILER_EMAIL'
}

export enum RecommendationsReviewActionEnum {
  SENT_REQUEST = 'SENT_REQUEST',
  SKIPPED = 'SKIPPED',
  VIEWED = 'VIEWED'
}

/**
 * What kind of rep is this? This is a guess based on what email the rep signed up with
 */
export enum RepKindEnum {
  EMPLOYEE = 'EMPLOYEE',
  INDEPENDENT = 'INDEPENDENT'
}

/**
 * Sort rep pricing requests by any of these options
 */
export enum RepPricingRequestSortingEnum {
  APPROVED_AT = 'APPROVED_AT',
  CREATED_AT = 'CREATED_AT',
  REP_ID = 'REP_ID',
  REQUESTED_AT = 'REQUESTED_AT',
  STATUS = 'STATUS'
}

/**
 * Possible sources of a pricing request.
 */
export enum RepPricingRequestSourceEnum {
  RECOMMENDATION = 'RECOMMENDATION',
  RECOMMENDATIONS_MODAL = 'RECOMMENDATIONS_MODAL',
  RECOMMENDATIONS_MODAL_ALL = 'RECOMMENDATIONS_MODAL_ALL',
  RECOMMENDATION_ALL = 'RECOMMENDATION_ALL',
  RECOMMENDATION_EMAIL = 'RECOMMENDATION_EMAIL',
  RECOMMENDATION_EMAIL_ALL = 'RECOMMENDATION_EMAIL_ALL',
  REP_REQUESTED = 'REP_REQUESTED',
  SELLER_INVITED = 'SELLER_INVITED'
}

/**
 * Status of a 'pricing request'. Will be approved/rejected by the seller
 */
export enum RepPricingRequestStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REMOVED = 'REMOVED',
  REQUESTED = 'REQUESTED'
}

/**
 * updating 'payment terms' for Buyer Requests
 */
export enum RequirePaymentUpfrontModeEnum {
  ALL_BUYERS = 'ALL_BUYERS',
  NEW_BUYERS = 'NEW_BUYERS'
}

/**
 * The type of role, or what the role grants access to
 */
export enum RoleAccessEnum {
  MARKETPLACE_INVITE = 'MARKETPLACE_INVITE',
  OWNER = 'OWNER',
  REP = 'REP',
  STAFF = 'STAFF'
}

/**
 * Sort roles by any of these options
 */
export enum RoleSortingEnum {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS'
}

/**
 * Invitation status. Applies to marketplace, rep and staff invites
 */
export enum RoleStatusEnum {
  ASSOCIATED = 'ASSOCIATED',
  INVITED = 'INVITED'
}

/**
 * What 'source' was used for the search results
 */
export enum SearchSourceEnum {
  ELASTICSEARCH = 'ELASTICSEARCH',
  WEAVIATE = 'WEAVIATE'
}

/**
 * where to shipping (AU, NZ)
 */
export enum SellerCountryEnum {
  AU = 'AU',
  NZ = 'NZ'
}

/**
 * Seller approval status for Marketplace
 */
export enum SellerMarketplaceApprovalStatusEnum {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED'
}

/**
 * Onboarding steps for Seller Hub
 */
export enum SellerOnboardingStepEnum {
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  CATALOG = 'CATALOG',
  CUSTOMERS = 'CUSTOMERS',
  IMAGES = 'IMAGES',
  INTEGRATIONS = 'INTEGRATIONS',
  MARKETPLACE_DETAILS = 'MARKETPLACE_DETAILS',
  PAYMENTS = 'PAYMENTS',
  PRODUCTS = 'PRODUCTS',
  SALES_REPS = 'SALES_REPS',
  SHIPPING = 'SHIPPING'
}

/**
 * Sort sellers by any of these options
 */
export enum SellerSortingEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_NAME = 'DISPLAY_NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/**
 * Seller account status indicates whether they have an active subscription or not
 */
export enum SellerStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TRIAL = 'TRIAL'
}

/**
 * What is the rate of calculation method
 */
export enum ShippingRuleCalculationMethodEnum {
  FIXED = 'FIXED',
  FREE = 'FREE',
  PERCENTAGE = 'PERCENTAGE'
}

/**
 * Sort items in either of these directions
 */
export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

/**
 * How to filter records by variety stock_on_hand
 */
export enum StockOnHandModeEnum {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

/**
 * Seller stripe status indicates whether subscription status
 */
export enum StripeAccountStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  PAST_DUE = 'PAST_DUE',
  TRIALING = 'TRIALING',
  UNPAID = 'UNPAID'
}

/**
 * How to filter records by tags
 */
export enum TagMatchingModeEnum {
  ALL = 'ALL',
  ANY = 'ANY'
}

/**
 * Status to verify their business name / tax details are valid (as per ABN / NZBN lookup)
 */
export enum TaxNumberCheckStatusEnum {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}

/**
 * The status of a user's phone number
 */
export enum UserPhoneStatusEnum {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}

/**
 * The channel to use for sending a verification code to a user's phone number
 */
export enum UserPhoneVerificationChannelEnum {
  CALL = 'CALL',
  SMS = 'SMS'
}

/**
 * Signup Source. How the user originally signed up
 */
export enum UserSignupSourceEnum {
  BUYER = 'BUYER',
  BUYER_INVITE = 'BUYER_INVITE',
  REP = 'REP',
  REP_INVITE = 'REP_INVITE',
  SELLER = 'SELLER',
  SELLER_STAFF_INVITE = 'SELLER_STAFF_INVITE'
}

/**
 * Filter product varieties by their hidden/visible state
 */
export enum VarietyHiddenModeEnum {
  ONLY_HIDDEN = 'ONLY_HIDDEN',
  ONLY_VISIBLE = 'ONLY_VISIBLE',
  WITH_HIDDEN = 'WITH_HIDDEN'
}

export interface AddressAttributesArguments {
  _destroy?: boolean | null
  city?: string | null
  contact?: string | null
  contactUserId?: string | null
  country?: string | null
  email?: string | null
  fax?: string | null
  id?: string | null
  kind?: AddressKindEnum | null
  locationType?: AddressLocationTypeEnum | null
  name?: string | null
  nickname?: string | null
  phone?: string | null
  phone2?: string | null
  postcode?: string | null
  state?: string | null
  street1: string
  street2?: string | null
}

/**
 * Autogenerated input type of AdjustInventoryMutation
 */
export interface AdjustInventoryMutationInput {
  clientMutationId?: string | null
  stockOnHand: number
  varietyId: string
}

/**
 * Autogenerated input type of AttachBuyerPaymentMethodMutation
 */
export interface AttachBuyerPaymentMethodMutationInput {
  billingAddress: AddressAttributesArguments
  clientMutationId?: string | null
  stripePaymentMethodId: string
}

export interface BuyerAttributesArguments {
  billingAddressAttributes?: AddressAttributesArguments | null
  billingAddressId?: string | null
  brandValueIds?: string[] | null
  businessCategoryTaxonomyId?: string | null
  departmentList?: string[] | null
  description?: string | null
  displayName?: string | null
  email?: string | null
  entityName?: string | null
  helpGuideCompleted?: boolean | null
  id?: string | null
  lastRecommendationsReviewAt?: any | null
  phone?: string | null
  productCategoryTaxonomyIds?: string[] | null
  shippingAddressAttributes?: AddressAttributesArguments | null
  shippingAddressId?: string | null
  slug?: string | null
  socialMediaDetailsAttributes?: SocialMediaDetailsAttributes | null
  taxNumber?: string | null
  taxNumberCheckStatus?: TaxNumberCheckStatusEnum | null
}

export interface BuyerNotificationPreferencesAttributesArguments {
  notificationDraftOrderIncomplete?: boolean | null
  notificationPricingRequestReminder?: boolean | null
  notificationRecommendedSellers?: boolean | null
}

export interface CatalogAttributesArguments {
  allowRepPricingRequests?: boolean | null
  createBackorders?: boolean | null
  currencyCode?: string | null
  customerGroupList?: string[] | null
  departmentList?: string[] | null
  enforceMinimumTotalOrderValueMarketplace?: boolean | null
  id?: string | null
  minimumTotalOrderValue?: number | null
  name?: string | null
  outOfStockThreshold?: number | null
  paymentTermsOffer?: PaymentTermsOfferEnum | null
  photo?: any | null
  repPromotionalTerritories?: any | null
  requireApprovalForMarket?: boolean | null
  salesTeamList?: string[] | null
  showInMarket?: boolean | null
  showOnIpad?: boolean | null
  slug?: string | null
  taxRate1?: number | null
  taxRate1Countries?: string[] | null
  taxRate1InPercent?: number | null
  taxRate1Label?: string | null
  terms?: string | null
}

/**
 * Arguments for filtering a collection of catalogs
 */
export interface CatalogFilterArguments {
  approvalStatus?: CatalogFilterApprovalStatusEnum | null
  currentAccount?: boolean | null
  departmentMode?: TagMatchingModeEnum | null
  departmentSlugs?: string[] | null
  excludeRepPromoted?: boolean | null
  freeShippingOver?: number | null
  hasIntegrations?: boolean | null
  id?: string | null
  minimumTotalOrderValue?: number | null
  onlyRepPromoted?: boolean | null
  productCategoryTaxonomySlugs?: string[] | null
  search?: string | null
  sellerCountries?: string[] | null
  sellerId?: string | null
  shipsTo?: string[] | null
  superDepartmentId?: string | null
  topBrandValueIds?: string[] | null
  withDeleted?: boolean | null
}

/**
 * Arguments for sorting a collection of catalogs
 */
export interface CatalogSortingArguments {
  sortBy?: CatalogSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

/**
 * Autogenerated input type of CheckPromotionCouponMutation
 */
export interface CheckPromotionCouponMutationInput {
  clientMutationId?: string | null
  couponCode: string
  promotionSlotId: string
}

/**
 * Autogenerated input type of CheckVerificationCodeMutation
 */
export interface CheckVerificationCodeMutationInput {
  clientMutationId?: string | null
  code: string
  phoneNumber: string
}

/**
 * Autogenerated input type of CloseSellerMutation
 */
export interface CloseSellerMutationInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of ConfigureUpfrontPaymentsMutation
 */
export interface ConfigureUpfrontPaymentsMutationInput {
  clientMutationId?: string | null
  requirePaymentUpfrontMode?: RequirePaymentUpfrontModeEnum | null
}

/**
 * Autogenerated input type of CreateAccountMutation
 */
export interface CreateAccountMutationInput {
  clientMutationId?: string | null
  isOnboarding?: boolean | null
}

/**
 * Autogenerated input type of CreateAddressMutation
 */
export interface CreateAddressMutationInput {
  addressableId: string
  addressableType: AddressableTypeEnum
  attributes: AddressAttributesArguments
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of CreateBuyerPricingRequestMutation
 */
export interface CreateBuyerPricingRequestMutationInput {
  accountableId?: string | null
  accountableType?: string | null
  catalogIds?: string[] | null
  clientMutationId?: string | null
  sellerId: string
  source?: PricingRequestSourceEnum | null
  token?: string | null
}

/**
 * Autogenerated input type of CreateCatalogMutation
 */
export interface CreateCatalogMutationInput {
  attributes: CatalogAttributesArguments
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of CreateCustomerMutation
 */
export interface CreateCustomerMutationInput {
  attributes: CustomerAttributesArguments
  clientMutationId?: string | null
  inviteToMarketplace?: boolean | null
}

/**
 * Autogenerated input type of CreateOrderManagementActionMutation
 */
export interface CreateOrderManagementActionMutationInput {
  attributes: OrderManagementActionAttributesArguments
  clientMutationId?: string | null
  orderId: string
  restockCancelledItems?: boolean | null
}

/**
 * Autogenerated input type of CreateOrderMutation
 */
export interface CreateOrderMutationInput {
  attributes: OrderAttributesArguments
  catalogId: string
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of CreateProductMutation
 */
export interface CreateProductMutationInput {
  attributes: ProductAttributesArguments
  catalogId: string
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of CreateRepMutation
 */
export interface CreateRepMutationInput {
  attributes?: RepAttributesArguments | null
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of CreateRepPricingRequestMutation
 */
export interface CreateRepPricingRequestMutationInput {
  accountableId?: string | null
  accountableType?: string | null
  catalogIds?: string[] | null
  clientMutationId?: string | null
  sellerId: string
  source?: RepPricingRequestSourceEnum | null
  token?: string | null
}

/**
 * Autogenerated input type of CreateTradeReferenceMutation
 */
export interface CreateTradeReferenceMutationInput {
  attributes: TradeReferenceAttributesArguments
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of CreateUserMutation
 */
export interface CreateUserMutationInput {
  attributes: UserAttributesArguments
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of CreateUserSessionMutation
 */
export interface CreateUserSessionMutationInput {
  clientMutationId?: string | null
  email: string
  password: string
}

export interface CustomerAttributesArguments {
  billingAddressAttributes?: AddressAttributesArguments | null
  businessCategoryTaxonomyId?: string | null
  comments?: string | null
  customerGroupList?: string[] | null
  id?: string | null
  name: string
  reference: string
  shippingAddressesAttributes?: AddressAttributesArguments[] | null
  territoryList?: string[] | null
}

/**
 * Arguments for filtering a collection of customers
 */
export interface CustomerFilterArguments {
  exported?: boolean | null
  groupMode?: TagMatchingModeEnum | null
  groups?: string[] | null
  noLinkedPricingRequest?: boolean | null
  pricingRequestStatus?: PricingRequestStatusEnum[] | null
  search?: string | null
  sellerId?: string | null
  territories?: string[] | null
  territoryMode?: TagMatchingModeEnum | null
  unexported?: boolean | null
}

/**
 * Arguments for sorting a collection of customers
 */
export interface CustomerSortingArguments {
  sortBy?: CustomerSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

/**
 * Autogenerated input type of DestroyBuyerPricingRequestMutation
 */
export interface DestroyBuyerPricingRequestMutationInput {
  clientMutationId?: string | null
  pricingRequestId: string
}

/**
 * Autogenerated input type of DestroyOrderLineItemMutation
 */
export interface DestroyOrderLineItemMutationInput {
  clientMutationId?: string | null
  id: string
  orderId: string
}

/**
 * Autogenerated input type of DestroyOrderManagementActionMutation
 */
export interface DestroyOrderManagementActionMutationInput {
  clientMutationId?: string | null
  orderManagementActionId: string
}

/**
 * Autogenerated input type of DestroyTradeReferenceMutation
 */
export interface DestroyTradeReferenceMutationInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of DestroyUserSessionMutation
 */
export interface DestroyUserSessionMutationInput {
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of DisconnectAccountMutation
 */
export interface DisconnectAccountMutationInput {
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of DownloadInvoiceMutation
 */
export interface DownloadInvoiceMutationInput {
  clientMutationId?: string | null
  invoiceId: string
}

/**
 * Autogenerated input type of DownloadPaymentProcessingInvoiceMutation
 */
export interface DownloadPaymentProcessingInvoiceMutationInput {
  clientMutationId?: string | null
  orderId: string
}

/**
 * Autogenerated input type of DuplicateOrderMutation
 */
export interface DuplicateOrderMutationInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Arguments for sorting a collection of inventory history
 */
export interface InventoryHistorySortingArguments {
  sortBy?: InventoryHistorySortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

/**
 * Autogenerated input type of InviteRepForSellerMutation
 */
export interface InviteRepForSellerMutationInput {
  attributes: RepRoleAttributesArguments
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of InviteStaffForSellerMutation
 */
export interface InviteStaffForSellerMutationInput {
  clientMutationId?: string | null
  email: string
}

export interface LocationAttributesArguments {
  _destroy?: boolean | null
  city?: string | null
  country?: string | null
  id?: string | null
  postcode?: string | null
  state?: string | null
}

/**
 * Autogenerated input type of LoginToExpressDashboardMutation
 */
export interface LoginToExpressDashboardMutationInput {
  clientMutationId?: string | null
}

export interface OrderAttributesArguments {
  cancelDate?: any | null
  category?: string | null
  customerId?: string | null
  hold?: boolean | null
  id?: string | null
  lines?: OrderLineItemAttributesArguments[] | null
  note?: string | null
  payment?: OrderPaymentTermsEnum | null
  shippingDate?: any | null
  shippingMethod?: OrderShippingMethodEnum | null
  shippingPartial?: boolean | null
  tos?: string | null
}

/**
 * Arguments for filtering a collection of orders
 */
export interface OrderFilterArguments {
  catalogId?: string | null
  catalogIsMarketplaceVisible?: boolean | null
  customerId?: string | null
  exported?: boolean | null
  fulfillmentStatus?: OrderFulfilmentStatusEnum[] | null
  hasPayment?: boolean | null
  orderPostedDateEnd?: any | null
  orderPostedDateStart?: any | null
  search?: string | null
  sellerHasReachedCap?: boolean | null
  sellerId?: string | null
  source?: OrderSourceEnum | null
  status?: OrderStatusEnum[] | null
  unexported?: boolean | null
  userId?: string | null
}

/**
 * Arguments for order line item when creating/updating an order
 */
export interface OrderLineItemAttributesArguments {
  productId: string
  quantity: number
  varietyId: string
}

/**
 * Arguments for order managment action
 */
export interface OrderManagementActionAttributesArguments {
  action: OrderManagementActionEnum
  comments?: string | null
  items?: OrderManagementActionItemAttributesArguments[] | null
  shippingDate?: any | null
  shippingMethod?: OrderShippingMethodEnum | null
  trackingNumbers?: string[] | null
}

/**
 * Arguments for order managment action items
 */
export interface OrderManagementActionItemAttributesArguments {
  cancelledQuantity?: number | null
  fulfilledQuantity?: number | null
  orderLineItemId?: string | null
  unfulfilledRemaining?: number | null
}

/**
 * Arguments for sorting a collection of orders
 */
export interface OrderSortingArguments {
  sortBy?: OrderSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

export interface PaymentMethodAttributesArguments {
  creditCardExpirationMonth: number
  creditCardExpirationYear: number
  creditCardFirstName: string
  creditCardLastName: string
  creditCardNumber: string
  cvv: number
}

export interface PictureAttributesArguments {
  _destroy?: boolean | null
  id?: string | null
  photo?: any | null
  position?: number | null
}

/**
 * Autogenerated input type of PostOrderMutation
 */
export interface PostOrderMutationInput {
  billingAddressId?: string | null
  clientMutationId?: string | null
  id: string
  shippingAddressId?: string | null
  shippingRuleRateId?: string | null
  stripePaymentIntentId?: string | null
  stripePaymentMethodId?: string | null
}

/**
 * Arguments for filtering a collection of pricing requests
 */
export interface PricingRequestFilterArguments {
  hasUnlinkedCustomer?: boolean | null
  orderId?: string | null
  requestedAtGte?: any | null
  requestedAtLte?: any | null
  search?: string | null
  sellerId?: string | null
  sellerSlug?: string | null
  status?: PricingRequestStatusEnum | null
  viewed?: boolean | null
}

/**
 * Arguments for sorting a collection of buyer pricing requests
 */
export interface PricingRequestSortingArguments {
  sortBy?: PricingRequestSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

/**
 * Type for pricing table
 */
export interface PricingTableArguments {
  tierQty: number
  unitPrice: number
}

/**
 * Autogenerated input type of PrintProductLabelsMutation
 */
export interface PrintProductLabelsMutationInput {
  averyBundleId: string
  catalogId: string
  clientMutationId?: string | null
  filter?: ProductFilterArguments | null
  options?: PrintProductLabelsOptionsArguments | null
}

/**
 * Options for printing products labels
 */
export interface PrintProductLabelsOptionsArguments {
  showBarcode?: boolean | null
  showBoxQuantity?: boolean | null
  showMinimumOrderQuantity?: boolean | null
  showOriginalPrice?: boolean | null
  showProductName?: boolean | null
}

/**
 * Autogenerated input type of ProcessBuyerPricingRequestMutation
 */
export interface ProcessBuyerPricingRequestMutationInput {
  catalogIds?: string[] | null
  clientMutationId?: string | null
  id: string
  linkToExistingCustomerId?: string | null
  requirePaymentUpfront?: boolean | null
  status?: PricingRequestStatusEnum | null
}

/**
 * Autogenerated input type of ProcessRepPricingRequestMutation
 */
export interface ProcessRepPricingRequestMutationInput {
  clientMutationId?: string | null
  id: string
  status?: RepPricingRequestStatusEnum | null
}

export interface ProductAttributesArguments {
  badge?: ProductBadgeEnum | null
  brand?: string | null
  brandValueIds?: string[] | null
  customTagList?: string[] | null
  extendedDescription?: string | null
  hero?: boolean | null
  hidden?: boolean | null
  name?: string | null
  picturesAttributes?: PictureAttributesArguments[] | null
  positionAdjustment?: number | null
  productCategoryTaxonomyId?: string | null
  taxable1?: boolean | null
  varietiesAttributes?: VarietyAttributesArguments[] | null
}

/**
 * Arguments for filtering a collection of catalogs
 */
export interface ProductFilterArguments {
  badges?: ProductBadgeEnum[] | null
  brandValueIds?: string[] | null
  catalogIds?: string[] | null
  catalogSlug?: string | null
  customTagMode?: TagMatchingModeEnum | null
  customTags?: string[] | null
  freeShippingOver?: number | null
  hiddenMode?: VarietyHiddenModeEnum | null
  minimumTotalOrderValue?: number | null
  productCategoryTaxonomyId?: string | null
  productCategoryTaxonomySlugs?: string[] | null
  search?: string | null
  sellerCountries?: string[] | null
  shipsTo?: string[] | null
  stockOnHandMode?: StockOnHandModeEnum | null
}

/**
 * Arguments for sorting a collection of products
 */
export interface ProductSortingArguments {
  sortBy?: ProductSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

/**
 * Arguments for filtering a collection of promotion slots
 */
export interface PromotionSlotFilterArguments {
  endDateGte?: any | null
  endDateLte?: any | null
  id?: string | null
  includePast?: boolean | null
  promotionType?: PromotionTypeEnum[] | null
  startDateGte?: any | null
  startDateLte?: any | null
  status?: PromotionSlotStatusEnum | null
}

/**
 * Autogenerated input type of PurchasePromotionSlotMutation
 */
export interface PurchasePromotionSlotMutationInput {
  clientMutationId?: string | null
  couponCode?: string | null
  id: string
  stripePaymentIntentId?: string | null
  stripePaymentMethodId?: string | null
}

/**
 * Autogenerated input type of RefreshOrderDetailsMutation
 */
export interface RefreshOrderDetailsMutationInput {
  clientMutationId?: string | null
  orderId: string
}

/**
 * Autogenerated input type of RemoveOutOfStockItemsMutation
 */
export interface RemoveOutOfStockItemsMutationInput {
  clientMutationId?: string | null
  orderId: string
}

export interface RepAttributesArguments {
  bio?: string | null
  brandValueIds?: string[] | null
  helpGuideCompleted?: boolean | null
  id?: string | null
  lastRecommendationsReviewAt?: any | null
  locationAttributes?: LocationAttributesArguments | null
  ownerAttributes?: UserAttributesArguments | null
  phone?: string | null
  photo?: any | null
  productCategoryTaxonomyIds?: string[] | null
  profileCompleted?: boolean | null
  repNotificationPreferenceAttributes?: RepNotificationPreferencesAttributesArguments | null
  retailTypesDescription?: string | null
  salesArea?: string | null
  tradeReferencesAttributes?: TradeReferenceAttributesArguments[] | null
  welcomeBannerDismissed?: boolean | null
}

export interface RepNotificationPreferencesAttributesArguments {
  notificationCatalogLaunch: boolean
  notificationRecommendedSellers?: boolean | null
}

/**
 * Arguments for filtering a collection of rep pricing requests
 */
export interface RepPricingRequestFilterArguments {
  orderId?: string | null
  requestedAtGte?: any | null
  requestedAtLte?: any | null
  search?: string | null
  sellerId?: string | null
  sellerSlug?: string | null
  status?: RepPricingRequestStatusEnum[] | null
  viewed?: boolean | null
}

/**
 * Arguments for sorting a collection of rep pricing requests
 */
export interface RepPricingRequestSortingArguments {
  sortBy?: RepPricingRequestSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

export interface RepRoleAttributesArguments {
  email: string
  salesTeamList?: string[] | null
  territoryList?: string[] | null
}

/**
 * Arguments for filtering a collection of roles
 */
export interface RoleFilterArguments {
  access?: RoleAccessEnum[] | null
  salesTeamMode?: TagMatchingModeEnum | null
  salesTeams?: string[] | null
  search?: string | null
  sellerId?: string | null
  status?: RoleStatusEnum[] | null
  territories?: string[] | null
  territoryMode?: TagMatchingModeEnum | null
}

/**
 * Arguments for sorting a collection of roles
 */
export interface RoleSortingArguments {
  sortBy?: RoleSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

/**
 * Autogenerated input type of SaveShippingRulesForCatalogMutation
 */
export interface SaveShippingRulesForCatalogMutationInput {
  catalogId: string
  clientMutationId?: string | null
  shippingRulesAttributes: ShippingRuleAttributesArguments[]
}

export interface SellerAttributesArguments {
  accountAddressAttributes?: AddressAttributesArguments | null
  banner?: any | null
  description?: string | null
  displayName?: string | null
  entityName?: string | null
  footerText?: string | null
  id?: string | null
  locationAttributes?: LocationAttributesArguments | null
  logo?: any | null
  slug?: string | null
  socialMediaDetailsAttributes?: SocialMediaDetailsAttributes | null
  stripeConnectIntegrationEnabled?: boolean | null
  taxNumber?: string | null
  taxNumberCheckStatus?: TaxNumberCheckStatusEnum | null
}

/**
 * Arguments for filtering a collection of sellers
 */
export interface SellerFilterArguments {
  freeShippingOver?: number | null
  minimumTotalOrderValue?: number | null
  productCategoryTaxonomySlugs?: string[] | null
  search?: string | null
  sellerCountries?: string[] | null
  shipsTo?: string[] | null
  topBrandValueIds?: string[] | null
}

export interface SellerNotificationPreferencesAttributesArguments {
  notificationCustomer?: boolean | null
  notificationDaily?: boolean | null
  notificationDraftOrders?: boolean | null
  notificationMyobIntegrationEvents?: boolean | null
  notificationOrder?: boolean | null
  notificationOutOfStockVarietyEnabled?: boolean | null
  notificationXeroIntegrationEvents?: boolean | null
}

/**
 * Arguments for sorting a collection of sellers
 */
export interface SellerSortingArguments {
  sortBy?: SellerSortingEnum | null
  sortDirection?: SortDirectionEnum | null
}

/**
 * Autogenerated input type of SendVerificationCodeMutation
 */
export interface SendVerificationCodeMutationInput {
  channel: UserPhoneVerificationChannelEnum
  clientMutationId?: string | null
  phoneNumber: string
}

export interface ShippingRuleAttributesArguments {
  _destroy?: boolean | null
  catalogId: string
  id?: string | null
  name: string
  shippingRegionIds?: string[] | null
  shippingRuleRatesAttributes?: ShippingRuleRateAttributesArguments[] | null
}

export interface ShippingRuleRateAttributesArguments {
  _destroy?: boolean | null
  id?: string | null
  name: string
  rateTable: ShippingRuleRateTableRowAttributesArguments[]
  shippingRuleId?: string | null
}

/**
 * Rate table JSON Object
 */
export interface ShippingRuleRateTableRowAttributesArguments {
  calculationMethod: ShippingRuleCalculationMethodEnum
  max?: string | null
  min: string
  rate?: string | null
}

/**
 * Autogenerated input type of SkipSellerRecommendationsMutation
 */
export interface SkipSellerRecommendationsMutationInput {
  clientMutationId?: string | null
  lastRecommendationsReviewAction: RecommendationsReviewActionEnum
}

export interface SocialMediaDetailsAttributes {
  createdAt?: any | null
  facebookUrl?: string | null
  id?: string | null
  instagramUsername?: string | null
  twitterUsername?: string | null
  updatedAt?: any | null
  websiteUrl?: string | null
}

export interface SubscriptionAttributesArguments {
  additionalCatalogs?: number | null
  additionalReps?: number | null
  additionalStaff?: number | null
  billingAddressAttributes?: AddressAttributesArguments | null
  coupon?: string | null
  paymentMethod?: PaymentMethodAttributesArguments | null
  planId?: string | null
}

/**
 * Autogenerated input type of TrackPromotionsEventMutation
 */
export interface TrackPromotionsEventMutationInput {
  clientMutationId?: string | null
  pageUrl: string
  promotionSlotId: string
}

export interface TradeReferenceAttributesArguments {
  _destroy?: boolean | null
  businessName: string
  contactName: string
  email: string
  id?: string | null
  phone: string
}

/**
 * Autogenerated input type of TransferAccountOwnershipMutation
 */
export interface TransferAccountOwnershipMutationInput {
  clientMutationId?: string | null
  roleId: string
}

/**
 * Autogenerated input type of UnsubscribeNotificationPreferenceMutation
 */
export interface UnsubscribeNotificationPreferenceMutationInput {
  accountableId: string
  accountableType: string
  clientMutationId?: string | null
  notificationAttribute: string
  token: string
}

/**
 * Autogenerated input type of UpdateAddressMutation
 */
export interface UpdateAddressMutationInput {
  attributes: AddressAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateBuyerMutation
 */
export interface UpdateBuyerMutationInput {
  attributes: BuyerAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateBuyerPaymentMethodMutation
 */
export interface UpdateBuyerPaymentMethodMutationInput {
  billingAddress: AddressAttributesArguments
  clientMutationId?: string | null
  stripePaymentMethodId: string
}

/**
 * Autogenerated input type of UpdateCatalogMutation
 */
export interface UpdateCatalogMutationInput {
  attributes: CatalogAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateChargifySubscription
 */
export interface UpdateChargifySubscriptionInput {
  attributes: SubscriptionAttributesArguments
  clientMutationId?: string | null
}

/**
 * Autogenerated input type of UpdateCustomerMutation
 */
export interface UpdateCustomerMutationInput {
  attributes: CustomerAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateOrderLineItemMutation
 */
export interface UpdateOrderLineItemMutationInput {
  attributes: OrderLineItemAttributesArguments
  clientMutationId?: string | null
  id?: string | null
  orderId: string
}

/**
 * Autogenerated input type of UpdateOrderManagementActionMutation
 */
export interface UpdateOrderManagementActionMutationInput {
  attributes: OrderManagementActionAttributesArguments
  clientMutationId?: string | null
  orderManagementActionId: string
}

/**
 * Autogenerated input type of UpdateOrderMutation
 */
export interface UpdateOrderMutationInput {
  attributes: OrderAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdatePaymentMethodMutation
 */
export interface UpdatePaymentMethodMutationInput {
  billingDetailsArguments?: AddressAttributesArguments | null
  clientMutationId?: string | null
  newStripePaymentMethodId: string
}

/**
 * Autogenerated input type of UpdateProductMutation
 */
export interface UpdateProductMutationInput {
  attributes: ProductAttributesArguments
  catalogId: string
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdatePromotionSlotContentMutation
 */
export interface UpdatePromotionSlotContentMutationInput {
  bodyText: string
  catalogId?: number | null
  clientMutationId?: string | null
  headline: string
  id: string
  variety1Description?: string | null
  variety1Id?: number | null
  variety1LinkCategory?: string | null
  variety1LinkTarget?: PromotionSlotContentLinkTargetEnum | null
  variety1Name?: string | null
  variety2Description?: string | null
  variety2Id?: number | null
  variety2LinkCategory?: string | null
  variety2LinkTarget?: PromotionSlotContentLinkTargetEnum | null
  variety2Name?: string | null
  variety3Description?: string | null
  variety3Id?: number | null
  variety3LinkCategory?: string | null
  variety3LinkTarget?: PromotionSlotContentLinkTargetEnum | null
  variety3Name?: string | null
  variety4Description?: string | null
  variety4Id?: number | null
  variety4LinkCategory?: string | null
  variety4LinkTarget?: PromotionSlotContentLinkTargetEnum | null
  variety4Name?: string | null
}

/**
 * Autogenerated input type of UpdateRepMutation
 */
export interface UpdateRepMutationInput {
  attributes: RepAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateRepPermissionsMutation
 */
export interface UpdateRepPermissionsMutationInput {
  clientMutationId?: string | null
  id: string
  salesTeamList?: string[] | null
  territoryList?: string[] | null
}

/**
 * Autogenerated input type of UpdateSellerMutation
 */
export interface UpdateSellerMutationInput {
  attributes: SellerAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateTradeReferenceMutation
 */
export interface UpdateTradeReferenceMutationInput {
  attributes: TradeReferenceAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UpdateUserMutation
 */
export interface UpdateUserMutationInput {
  attributes: UserAttributesArguments
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of UploadImageMutation
 */
export interface UploadImageMutationInput {
  catalogId: string
  clientMutationId?: string | null
  photo: any
  replaceImagesOlderThan?: any | null
}

/**
 * Autogenerated input type of UploadProductsCSVMutation
 */
export interface UploadProductsCSVMutationInput {
  catalogId: string
  clientMutationId?: string | null
  csv: any
  csvFormat?: CSVImportFormatEnum | null
  destroyOtherProducts?: boolean | null
  updateExistingProducts?: boolean | null
}

/**
 * Autogenerated input type of UploadSOHCSVMutation
 */
export interface UploadSOHCSVMutationInput {
  catalogId: string
  clientMutationId?: string | null
  csv: any
  sohFormat: CSVImportSOHFormatEnum
}

export interface UserAttributesArguments {
  buyerNotificationPreferencesAttributes?: BuyerNotificationPreferencesAttributesArguments | null
  email?: string | null
  firstName?: string | null
  id?: string | null
  lastName?: string | null
  newEmail?: string | null
  password?: string | null
  passwordConfirmation?: string | null
  phone?: string | null
  sellerNotificationPreferencesAttributes?: SellerNotificationPreferencesAttributesArguments | null
  signupSource?: UserSignupSourceEnum | null
  termsAccepted?: boolean | null
  timezone?: string | null
}

export interface VarietyAttributesArguments {
  _destroy?: boolean | null
  barcode?: string | null
  boxQuantity?: number | null
  id?: string | null
  minimumOrderQuantity?: number | null
  name?: string | null
  position?: number | null
  price?: number | null
  pricingTable?: PricingTableArguments[] | null
  rrp?: number | null
  salePrice?: number | null
  sku: string
  stockDueDate?: any | null
  stockOnHand?: number | null
  unit: string
}

/**
 * Arguments for filtering a varieties
 */
export interface VarietyFilterArguments {
  ids?: string[] | null
  sku?: string | null
  stockOnHandMode?: StockOnHandModeEnum | null
}

/**
 * Autogenerated input type of ViewedBuyerPricingRequestMutation
 */
export interface ViewedBuyerPricingRequestMutationInput {
  clientMutationId?: string | null
  id: string
}

/**
 * Autogenerated input type of ViewedRepPricingRequestMutation
 */
export interface ViewedRepPricingRequestMutationInput {
  clientMutationId?: string | null
  id: string
}

//==============================================================
// END Enums and Input Objects
//==============================================================
